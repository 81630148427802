import React, {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import AssistantMessage from "./AssistantMessage";
import UserMessage from "./UserMessage";
import { Message } from "../types/Message";
import TextareaAutosize from "react-textarea-autosize";
import { Agent } from "../types/Agent";
import { Transition } from "@headlessui/react";
import { Menu } from "@headlessui/react";
import { isMobileDevice } from "../utils";
import UploadFile from "./UploadFile";
function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ");
}

const ChatBox = ({
  fileList,
  setFileList,
  messages,
  onUserMessageAdded,
  onRequestNewConversation,
  onScrollReachTop,
  agentInfo,
  agentUuid,
  threadId,
}: {
  fileList: any[];
  setFileList: (fileList: any[]) => void;
  messages: Message[];
  onUserMessageAdded: (message: any, fileList?: any[]) => Promise<void>;
  onRequestNewConversation: any;
  onScrollReachTop: any;
  agentInfo?: Agent;
  agentUuid: string;
  threadId: string;
}) => {
  const inputRef = useRef<HTMLTextAreaElement>(null);
  const [chatContainer, setChatContainer] = useState<any>();
  const [shouldShowTypingMessage, setShouldShowTypingMessage] = useState(false);
  const [isAtBottom, setIsAtBottom] = useState(true);
  const [newMessageIndicator, setNewMessageIndicator] = useState(false);
  const [copyright, setCopyright] = useState("");
  const [viewportHeight, setViewportHeight] = useState(window.innerHeight);
  const [isSentMessage, setIsSentMessage] = useState(false);
  const [hasReachedTop, setHasReachedTop] = useState(false);
  const [previousHeight, setPreviousHeight] = useState(0);
  const [message, setMessage] = useState("");

  useEffect(() => {
    // check if last message is assistant message
    if (
      messages.length > 0 &&
      messages[messages.length - 1].role === "assistant"
    ) {
      console.debug(
        "Last message is assistant message, setting shouldShowTypingMessage to false",
        messages
      );
      setShouldShowTypingMessage(false);
    }
  }, [messages]);

  useEffect(() => {
    if (!shouldShowTypingMessage && inputRef && inputRef.current) {
      inputRef.current.focus();
    }
  }, [shouldShowTypingMessage]);

  useEffect(() => {
    if (isAtBottom && chatContainer) {
      chatContainer.scrollTo({
        top: chatContainer.scrollHeight + 32,
        behavior: "instant",
      });
    } else {
      setNewMessageIndicator(true);
    }
  }, [messages, chatContainer, copyright]);

  const handleScroll = useCallback(() => {
    if (chatContainer) {
      const threshold = 50;
      const isUserAtBottom =
        chatContainer.scrollHeight - chatContainer.scrollTop <=
        chatContainer.clientHeight + threshold;
      setIsAtBottom(isUserAtBottom);
      if (isUserAtBottom) {
        setNewMessageIndicator(false);
      }

      const scrollTop = chatContainer.scrollTop;
      if (scrollTop < 50 && !hasReachedTop) {
        console.debug("Reached the top of the chat container");
        setHasReachedTop(true);
        setPreviousHeight(chatContainer.scrollHeight);
        onScrollReachTop();
      } else if (scrollTop >= 50) {
        setHasReachedTop(false);
      }
    }
  }, [onScrollReachTop, chatContainer, hasReachedTop]);

  useEffect(() => {
    if (chatContainer) {
      chatContainer.addEventListener("scroll", handleScroll);
      return () => chatContainer.removeEventListener("scroll", handleScroll);
    }
  }, [handleScroll]);

  const sendMessage = async () => {
    if (inputRef.current) {
      const message = inputRef.current.value;
      if (message.trim() === "") return;
      inputRef.current.value = "";
      setIsSentMessage(true);
      setShouldShowTypingMessage(true);
      await onUserMessageAdded(message, fileList);
      setTimeout(() => {
        if (isAtBottom) {
          chatContainer.scrollTo({
            top: chatContainer.scrollHeight,
            behavior: "smooth",
          });
        }
      }, 0);
      setMessage("");
      setFileList([]);
    }
  };

  const onkeydown = (e: any) => {
    if (e.key === "Enter" && !e.shiftKey) {
      sendMessage();
      e.preventDefault();
    }
  };

  const createNewConversation = useCallback(() => {
    onRequestNewConversation();
    setIsSentMessage(false);
  }, [onRequestNewConversation]);

  useEffect(() => {
    setCopyright(agentInfo?.copyright || "");
  }, [agentInfo]);

  // Add viewport height change detection
  useEffect(() => {
    const handleResize = () => {
      const newHeight = window.innerHeight;
      if (newHeight !== viewportHeight) {
        setViewportHeight(newHeight);
        // Scroll to bottom when keyboard appears/disappears on mobile
        if (isMobileDevice() && chatContainer && isAtBottom) {
          setTimeout(() => {
            chatContainer.scrollTo({
              top: chatContainer.scrollHeight,
              behavior: "instant",
            });
          }, 100);
        }
      }
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [viewportHeight, chatContainer, isAtBottom]);

  const startingQuestions = useMemo(
    () =>
      [
        agentInfo?.startingQuestion1,
        agentInfo?.startingQuestion2,
        agentInfo?.startingQuestion3,
      ].filter(Boolean) as string[],
    [agentInfo]
  );

  useEffect(() => {
    if (chatContainer && previousHeight) {
      if (chatContainer.scrollHeight > previousHeight) {
        chatContainer.scrollTo({
          top: chatContainer.scrollHeight - previousHeight,
          behavior: "instant",
        });
        setPreviousHeight(0);
      }
    }
  }, [chatContainer, previousHeight, messages]);

  return (
    <div className="flex-grow h-full flex flex-col bg-gray-50">
      <style>
        {`
          .custom-upload-button .ant-upload.ant-upload-select {
            display: block !important;
          }
          .bottom-border-only .ant-input {
            border-left: none;
            border-right: none;
            border-top: none;
            border-radius: 0;
            box-shadow: none;
            max-height: 36px;
            border-width: 2px;
            padding: 2px;
          }
          .bottom-border-only .ant-input:focus {
            box-shadow: none;
            border-color: #d9d9d9;
            border-bottom-color: #1890ff;
            border-width: 2px;
          }
        `}
      </style>
      <div
        className={`w-full h-15 p-1 bg-blue-950 shadow-md ${
          !isMobileDevice() ? "rounded-xl" : ""
        } rounded-bl-none rounded-br-none`}
      >
        <div className="flex p-2 align-middle items-center">
          {/* <div className="p-2 md:hidden rounded-full mr-1 hover:bg-purple-500 text-white">
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M10 19l-7-7m0 0l7-7m-7 7h18" />
                        </svg>
                    </div> */}
          <div className="rounded-full p-1/2">
            <img
              className="w-10 h-10 rounded-full"
              src={
                agentInfo?.botImgUrl ||
                `${process.env.REACT_APP_STATIC_FILE_PATH}/logo192.png`
              }
              alt="avatar"
            />
          </div>
          <div className="flex-grow p-2">
            <div
              className={`text-gray-50 font-semibold ${
                isMobileDevice() ? "text-lg" : "text-base"
              }`}
            >
              {agentInfo?.name || "Conductify AI Agent"}
            </div>
            {/* <div className="flex items-center">
              <div className={`w-2 h-2 bg-green-300  rounded-full`}></div>
              <div className="text-xs text-gray-50 ml-1">Online</div>
            </div> */}
          </div>
          <Menu as="div" className="relative inline-block text-left ">
            <div>
              <Menu.Button className="p-2 text-white cursor-pointer hover:bg-gray-500 rounded-full">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M12 5v.01M12 12v.01M12 19v.01M12 6a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2z"
                  />
                </svg>
              </Menu.Button>
            </div>

            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white  shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                <div className="py-1">
                  <Menu.Item>
                    {({ active }) => (
                      // eslint-disable-next-line jsx-a11y/anchor-is-valid
                      <a
                        href="#"
                        onClick={createNewConversation}
                        className={classNames(
                          active
                            ? "hover:bg-gray-100 text-white-900"
                            : "text-white-700",
                          "block px-4 py-2",
                          isMobileDevice() ? "text-base" : "text-sm"
                        )}
                      >
                        New conversation
                      </a>
                    )}
                  </Menu.Item>
                </div>
              </Menu.Items>
            </Transition>
          </Menu>
        </div>
      </div>
      {/* Chat container */}
      <div
        ref={(tag) => setChatContainer(tag)}
        className={`w-full flex-grow bg-gray-50 px-4 py-2 overflow-y-auto ${
          messages.length === 0 ? "flex flex-col justify-end" : ""
        }`}
      >
        {messages.map((message, index) => {
          return message.role === "assistant" ? (
            <AssistantMessage key={index} message={message} />
          ) : (
            <UserMessage key={index} message={message} />
          );
        })}
        {shouldShowTypingMessage && (
          <div className="flex items-end">
            <div className="p-3 bg-gray-200 mx-3 my-1 rounded-2xl rounded-bl-none">
              <div className="flex space-x-1 justify-center items-center">
                <span className="sr-only">Loading...</span>
                <div className="h-2 w-2 bg-gray-500 rounded-full animate-bounce [animation-delay:-0.3s]"></div>
                <div className="h-2 w-2 bg-gray-500 rounded-full animate-bounce [animation-delay:-0.15s]"></div>
                <div className="h-2 w-2 bg-gray-500 rounded-full animate-bounce"></div>
              </div>
            </div>
          </div>
        )}
        {/* {newMessageIndicator && !isAtBottom && (
                    <div className="new-message-indicator">New messages</div>
                )} */}
        {!isSentMessage && (
          <div className="flex flex-col space-y-2 px-4 py-2 items-center w-full">
            {startingQuestions.map((startingQuestion, index) => (
              <button
                key={index}
                onClick={() => {
                  if (inputRef.current) {
                    inputRef.current.value = startingQuestion;
                    sendMessage();
                  }
                }}
                className={`px-4 py-2 rounded-2xl border border-blue-400 bg-white hover:bg-gray-50 text-gray-700 transition-colors shadow-smw-fit max-w-[85%] ${
                  isMobileDevice() ? "text-base" : "text-sm"
                }`}
              >
                {startingQuestion}
              </button>
            ))}
          </div>
        )}
      </div>
      <div className="px-4 py-2">
        <div className="p-1 bg-gray-100 border rounded-lg border-gray-300">
          <div className="flex items-center">
            <div className="search-chat flex flex-grow items-center p-2">
              <TextareaAutosize
                onKeyDown={onkeydown}
                ref={inputRef}
                className={`disabled:cursor-not-allowed disabled:placeholder:text-slate-200 input text-gray-700 md:text-sm ${
                  isMobileDevice() ? "xs:text-base" : "text-sm"
                } focus:outline-none bg-gray-100 flex-grow`}
                placeholder="Type a message..."
                maxRows={3}
                style={{ height: 24, resize: "none" }}
                disabled={shouldShowTypingMessage}
              />
              <UploadFile
                sendMessage={sendMessage}
                onUserMessageAdded={onUserMessageAdded}
                shouldShowTypingMessage={shouldShowTypingMessage}
                setShouldShowTypingMessage={setShouldShowTypingMessage}
                botUuid={agentUuid}
                threadId={threadId}
              />
              <button
                disabled={shouldShowTypingMessage}
                onClick={sendMessage}
                className={`disabled:cursor-not-allowed disabled:text-slate-200 bg-gray-100  flex justify-center items-center text-gray-800 ${
                  isMobileDevice() ? "mr-0 size-5" : "mr-0"
                }`}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-6 h-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M6 12 3.269 3.125A59.769 59.769 0 0 1 21.485 12 59.768 59.768 0 0 1 3.27 20.875L5.999 12Zm0 0h7.5"
                  />
                </svg>
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="flex justify-start items-center bg-gray-50 pb-2 px-4">
        <div
          className={`text-gray-500 italic ${
            isMobileDevice() ? "text-sm" : "text-xs"
          }`}
        >
          {copyright ? (
            <div className="flex items-center">
              Copyright by{" "}
              <div
                className="w-auto inline pl-1"
                dangerouslySetInnerHTML={{ __html: copyright }}
              ></div>
            </div>
          ) : (
            <div className="flex items-center">
              <span>Powered by</span>
              <img
                src={`${process.env.REACT_APP_STATIC_FILE_PATH}/logo_full.png`}
                alt="logo"
                className="w-auto h-5 inline pl-1"
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ChatBox;
